import React, { Component } from 'react';
import soon from '../../assets/svg/available_soon.svg';
import './dashboard.css';

class LoginScreen extends Component {
  render() {
    let currentPage = this.props.currentPage;
    return (
      <div>
        <div className="container dashboard">
          <div className="row">
            <div className="col-md-12 center">
              <div>
                <h4>{currentPage} Page</h4>
                <img src={soon} alt="Available Soon" />
                <br />
                <p>Available Soon</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginScreen;
