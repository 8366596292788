import React, { PureComponent } from 'react';
import profilePic from '../../assets/svg/profile_avatar.svg';
import './sidebar.css';

class Header extends PureComponent {
  render() {
    const status = this.props.status;
    const toggleSideBar = this.props.toggle;
    const home = this.props.home;
    const users = this.props.users;
    const reviews = this.props.reviews;
    const settings = this.props.settings;
    const logout = this.props.logout;
    if (!status) return null;

    // Replace values from server
    const name = 'Harkian';
    const role = 'Admin';
    const profilePicture = profilePic;

    return (
      <div className="sidebar">
        <div className="container">
          <img src={profilePicture} alt="ProfilePicture" />
          <h4>{name}</h4>
          <h6>{role}</h6>
          <hr />
          <ul>
            <li onClick={home}>
              <i className="fas fa-home"></i> Home
            </li>
            <li onClick={users}>
              <i className="fas fa-users"></i> Users
            </li>
            <li onClick={reviews}>
              <i className="fas fa-star"></i> Reviews
            </li>
            <li onClick={settings}>
              <i className="fas fa-cog"></i> Settings
            </li>
          </ul>
          <hr />
          <ul>
            <li onClick={logout}>
              <i className="fas fa-sign-out-alt"></i> Logout
            </li>
          </ul>
          <p>
            Hark Admin <br /> v 1.0.0
          </p>
        </div>
        <div className="blank-area" onClick={toggleSideBar}></div>
      </div>
    );
  }
}

export default Header;
