import React, { Component } from 'react';
import login_img from '../../assets/svg/login.svg';
import '../../assets/css/App.css';
import './login.css';

class LoginScreen extends Component {
  render() {
    const login = this.props.auth;
    return (
      <div className="container login">
        <div className="row">
          <div className="col-12 center" style={{ minHeight: '100vh' }}>
            <div>
              <img src={login_img} alt="login_image" />
              <br />
              <button id="login" onClick={login}>
                {' '}
                Login{' '}
              </button>
              <p>
                By clicking Login, you agree to our{' '}
                <a href="https://harkdevelopers.com/legal/terms">Terms</a>,{' '}
                <br />
                <a href="https://harkdevelopers.com/legal/data-policy">
                  Data Policy
                </a>{' '}
                and{' '}
                <a href="https://harkdevelopers.com/legal/cookie-policy">
                  Cookie Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginScreen;
