import React, { Component } from 'react';
import SideBar from '../SideBar';
import './header.css';

class Header extends Component {
  state = {
    sidebar: {
      count: 0,
      status: false,
    },
  };

  page = this.props.updatePage;

  home = () => {
    this.page('Home');
  };

  users = () => {
    this.page('Users');
  };

  reviews = () => {
    this.page('Reviews');
  };

  settings = () => {
    this.page('Settings');
  };

  toggleSideBar = () => {
    const sidebar = this.state.sidebar;
    let count = sidebar.count;
    if (count % 2 === 0) this.updateSidebar(count, true);
    else this.updateSidebar(count, false);
  };

  updateSidebar = (count, status) => {
    count++;
    this.setState({
      sidebar: {
        count,
        status,
      },
    });
  };

  render() {
    const logout = this.props.auth;
    const status = this.state.sidebar.status;
    return (
      <div>
        <SideBar
          status={status}
          toggle={this.toggleSideBar}
          home={this.home}
          users={this.users}
          reviews={this.reviews}
          settings={this.settings}
          logout={logout}
        />
        <header>
          <button className="btn" onClick={this.toggleSideBar}>
            <i className="fas fa-bars"></i>
          </button>
          <h5 className="hide-on-mobile">HARK Admin</h5>
          <input type="text" placeholder="Search for users, reviews" />
          <nav>
            <ul>
              <li value="home" onClick={this.home}>
                <i className="fas fa-home"></i>
              </li>
              <li onClick={this.users}>
                <i name="users" className="fas fa-users"></i>
              </li>
              <li name="reviews" onClick={this.reviews}>
                <i className="fas fa-star"></i>
              </li>
              <li name="settings" onClick={logout}>
                <i className="fas fa-sign-out-alt"></i>
              </li>
            </ul>
          </nav>
        </header>
      </div>
    );
  }
}

export default Header;
