import React, { Component } from 'react';
import LoginScreen from './components/LoginScreen/';
import Dashboard from './components/Dashboard/';
import Header from './components/Header/';

export default class App extends Component {
  state = {
    login: false,
    currentPage: 'Home',
  };

  signin = () => {
    this.setState({
      login: true,
    });
  };
  signout = () => {
    this.setState({
      login: false,
    });
  };

  updatePage = (currentPage) => {
    this.setState({
      currentPage,
    });
  };

  render() {
    const { login, currentPage } = this.state;
    let screen = (
      <div>
        <Header auth={this.signout} updatePage={this.updatePage} />
        <Dashboard currentPage={currentPage} />
      </div>
    );
    if (!login) screen = <LoginScreen auth={this.signin} />;
    return <div>{screen}</div>;
  }
}
